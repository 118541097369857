import { EmailNotificationInput } from '.'
import { sendEmail } from './index'
import { QueryEndPoints, Service } from '../../enums'
import { TE, pipe } from '../../../common/helpers/fp-ts'
import { constructUrlSearchParams } from '../url'
import { operations } from '../../../types/goapi'
import { safeFetch } from '../fetchApi'

export function sendCustomerOrderDeliveredEmail(input: EmailNotificationInput) {
  return pipe(
    safeFetch<
      operations['customer-order-delivered-email-information']['responses']['200']['schema']
    >({
      endpoint: QueryEndPoints.EmailCustomerOrderDelivered,
      service: Service.GOAPI,
      method: 'GET',
      urlSearchParams: constructUrlSearchParams<
        operations['customer-order-delivered-email-information']['parameters']['query']
      >({
        'customer-order-id': String(input.orderId),
      }),
    }),
    TE.chain(d =>
      d.send_email ? TE.right(d) : TE.left(new Error("Customer's email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: `Hi ${d.first_name}, Congratulations on your new building!`,
            messageIsHtml: true,
            recipient: d.email,
            fromNoReply: false,
            tags: ['Customer - order delivered'],
            senderName: d.company_name,
            senderEmail: d.company_email,
            template: 'customer-order-delivered',
            variables: {
              RTOCompanyName: d.rto_company_name,
              companyAddress: d.company_address,
              companyEmail: d.company_email,
              companyLogo: d.company_logo_url,
              companyName: d.company_name,
              companyPhone: d.company_phone,
              customerOrDealerName: d.first_name,
              dealerUserEmail: d.dealer_user_email,
              dealerUserName: d.dealer_user_name,
              dealerUserPhone: d.dealer_user_phone,
              deliveryCertificate: d.delivery_certificate_url || input.deliveryCertificateUrl,
              isRTO: d.is_rto,
            },
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}
